import { apiGetData } from '@/resource/register'

export default {
  data() {
    return {
      form: {
        step: 3,
        employmentFinanceAnswers: [],
        tradingAnswers: [],
      },
      apiForm: {},
    }
  },
  computed: {
    EFQuestion() {
      return this.$store.state.register.EFQuestion
    },
    tradingQuestions() {
      return this.$store.state.register.tradingQuestions
    },
    lang() {
      return this.$store.state.common.lang
    },
    disableField() {
      return this.$store.state.register.disableField
    },
  },
}
