<template>
  <el-form :model="apiForm" ref="ruleForm">
    <div class="title">
      <h3>{{ $t('register.moreAboutYou.pageTitle1') }}</h3>
    </div>

    <section>
      <p>{{ $t('register.moreAboutYou.complianceExplain') }}</p>
    </section>

    <el-row :gutter="60">
      <el-col :xs="24" :sm="12" :md="12" v-for="q in filteredQuestion" v-bind:key="q.pkey">
        <div class="labelTitle">
          <span class="required_icon">*</span>
          <label for="">{{ q.desc }}</label>
        </div>
        <el-form-item
          :prop="deleteDot(`${0}_${q.pkey}`)"
          :rules="{ required: true, message: $t('common.formValidation.answerReq'), trigger: 'blur' }"
        >
          <SelectForm
            v-model="apiForm[deleteDot(`${0}_${q.pkey}`)]"
            :data-testid="deleteDot(`question_${q.pkey}`)"
            :disabled="disableField"
          >
            <el-option
              v-for="qo in q.questionOptions"
              :key="deleteDot(`${qo.pkey}`)"
              :value="deleteDot(`${qo.pkey}`)"
              :label="qo.desc"
              :data-testid="deleteDot(`answer_${qo.pkey}`)"
            ></el-option>
          </SelectForm>
        </el-form-item>
      </el-col>
    </el-row>

    <div class="title">
      <h3>{{ $t('register.moreAboutYou.pageTitle2') }}</h3>
    </div>

    <el-row :gutter="60">
      <el-col :xs="24" :sm="12" :md="12" v-for="q in tradingQuestions" v-bind:key="q.questionId">
        <div class="labelTitle">
          <span class="required_icon">*</span>
          <label for="">{{ q.desc }}</label>
        </div>
        <el-form-item
          :prop="`${1}_${q.questionId}`"
          :rules="{ required: true, message: $t('common.formValidation.answerReq'), trigger: 'blur' }"
        >
          <SelectForm
            v-model="apiForm[`${1}_${q.questionId}`]"
            :data-testid="`question_1_${q.questionId}`"
            :disabled="disableField"
          >
            <el-option
              v-for="qo in q.questionOptions"
              :key="qo.id"
              :value="qo.id"
              :label="qo.desc"
              :data-testid="qo.id"
            ></el-option>
          </SelectForm>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import moreAboutYou from '@/mixins/register/moreAboutYou';
import { apiGetData } from '@/resource/register';

export default {
  mixins: [moreAboutYou],
  data() {
    return {
      filteredQuestion: []
    };
  },
  watch: {
    apiForm: {
      immediate: true,
      handler: function (val, oldVal) {
        this.filteredQuestion = ['traderEmployed', 'traderSelf_employed'].includes(
          this.apiForm['0_traderreg2employmentstatus']
        )
          ? this.EFQuestion
          : this.EFQuestion.filter(e => e.pkey !== 'trader.reg2.occupation');
      },
      deep: true
    },
    EFQuestion(val) {
      this.filteredQuestion = [...val];
    }
  },
  methods: {
    deleteDot(val) {
      return val.toString().replace(/\./g, '');
    },
    submit() {
      let employmentFinanceAnswers = [],
        tradingAnswers = [];
      Object.keys(this.apiForm).map((m, i) => {
        const split = m.split('_');
        if (split[0] == 0) {
          let qIndex = this.EFQuestion.findIndex(item => this.deleteDot(item.pkey) === split[1]);
          let qOptions = this.EFQuestion[qIndex].questionOptions;
          let qId = this.EFQuestion[qIndex].questionId;
          let optionId = qOptions.find(item => this.deleteDot(item.pkey) === this.apiForm[m]).id;
          employmentFinanceAnswers.push({ questionId: qId, answers: [optionId] });
        } else {
          tradingAnswers.push({ questionId: split[1], answers: [this.apiForm[m]] });
        }
      });
      if (this.filteredQuestion.length < employmentFinanceAnswers.length) {
        let answerIndex = this.EFQuestion.findIndex(item => item.pkey === 'trader.reg2.occupation');
        let answerIndexId = this.EFQuestion[answerIndex].questionId;
        this.form.employmentFinanceAnswers = employmentFinanceAnswers.filter(element => {
          return element.questionId !== answerIndexId;
        });
      } else {
        this.form.employmentFinanceAnswers = employmentFinanceAnswers;
      }
      this.form.tradingAnswers = tradingAnswers;
    },
    getUserData() {
      apiGetData(3).then(resp => {
        const userData = resp.data.data;
        let dataObj = {};
        userData.employmentFinanceAnswers.map((m, i) => {
          let qIndex = this.EFQuestion.findIndex(item => item.questionId === m.questionId);
          let qPkey = this.EFQuestion[qIndex].pkey;
          let qOptions = this.EFQuestion[qIndex].questionOptions;
          let optionPkey = qOptions.find(item => item.id === m.answers[0]).pkey;
          if (optionPkey !== '') dataObj[this.deleteDot(`${0}_${qPkey}`)] = this.deleteDot(optionPkey);
        });
        userData.tradingAnswers.map((m, i) => {
          dataObj[`1_${m.questionId}`] = m.answers[0];
        });
        this.apiForm = dataObj;
      });
    }
  },
  mounted() {
    const vm = this;
    const promise1 = this.$store.dispatch('register/actionEFQuestion', this.lang);
    promise1.then(function (res) {
      let questionForm = {};
      res.map((mc, j) => {
        questionForm[vm.deleteDot(`${0}_${mc.pkey}`)] = '';
      });
      vm.apiForm = questionForm;
      vm.getUserData();
    });
    const promise2 = this.$store.dispatch('register/actionTradingQuestion', this.lang);
    promise2.then(function (res) {
      let questionForm = {};
      res.map((mc, j) => {
        questionForm[`${1}_${mc.questionId}`] = '';
      });
      vm.apiForm = questionForm;
      vm.getUserData();
    });
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/register/moreAboutYou.scss';
</style>
